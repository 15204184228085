<template>
<div>
  <v-row class="px-5">
    <v-col v-if="tableType !== 'JOB'">
      <span class="font-weight-bold text-h6 black--text">
        Candidates:
      </span>
    </v-col>
    <v-col class="d-flex justify-end">
      <v-btn
        color="error"
        icon
        @click="deleteMultipleCandidates"
        :disabled="candidateSelection.length === 0 || tableType === 'JOB'"
        class="text-none text-body-1"
      >
        <v-icon color="error">
          {{ mdiTrashCan }}
        </v-icon>
      </v-btn>
      <SmallConfirmation ref="delconfirm" />

    <div v-if="tableType !== 'JOB'">
      <v-dialog v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            color="primary"
            text
            class="text-none text-body-1"
          >
            <v-icon left color="primary">
              {{ mdiCloudUpload }}
            </v-icon>
            Invite Candidates
          </v-btn>
        </template>
        
        <v-card-text class="px-8 py-8">
          <Invite @closeDialog="returnToAllAssessment"/>
        </v-card-text>
      </v-dialog>
    </div>
    <div v-else>
      <v-btn
        color="primary"
        text
        class="text-none text-body-1"
        :loading="downloadsLoading"
        height="35"
        @click="bulkDownloadCandidates"
        download="candidates.csv"
        :href="`data:application/octet-stream,${encodeURI(this.candidateCsvText)}`"
      >
        <v-icon left color="primary">
          {{ mdiCloudDownload }}
        </v-icon>
        Download Candidates
      </v-btn>
    </div>

    </v-col>
  </v-row>
  <v-row class="d-flex justify-space-between px-5">
    <v-col cols="4">
      <v-text-field
        outlined
        v-model="searchCandidate"
        :prepend-inner-icon="mdiMagnify"
        dense
        label="Search Candidates"
        single-line
        hide-details
      ></v-text-field>
    </v-col>
    <v-col cols="8">
      <v-row class="justify-end">
        <v-col cols="4" v-if="tableType !== 'JOB'">
          <v-select
            :items="ratingFilters"
            label="Ratings"
            v-model="ratingsSelected"
            outlined
            dense
            chips
            clearable
            :menu-props="{
              maxHeight: '400',
              maxWidth: '300',
              bottom: true,
              offsetY: true,
            }"
            multiple
            placeholder="Ratings"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                small
                close
                @click:close="removeSelection('RATING', item)"
              >
                <span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" v-if="tableType !== 'JOB'">
          <v-select
            :items="statusFilters"
            label="Status"
            v-model="statusSelected"
            outlined
            dense
            chips
            clearable
            :menu-props="{
              maxHeight: '400',
              maxWidth: '300',
              bottom: true,
              offsetY: true,
            }"
            multiple
            placeholder="Status"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                small
                close
                @click:close="removeSelection('STATUS', item)"
              >
                <span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col cols="4" v-if="tableType !== 'JOB'">
          <v-select
            :items="stageFilters"
            label="Stage"
            v-model="stageSelected"
            outlined
            dense
            chips
            clearable
            :menu-props="{
              maxHeight: '400',
              maxWidth: '300',
              bottom: true,
              offsetY: true,
            }"
            multiple
            placeholder="Stage"
          >
            <template v-slot:selection="{ item, index }">
              <v-chip
                small
                close
                @click:close="removeSelection('STAGE', item)"
              >
                <span>{{ item.text }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
      <!-- candidate table -->
      <v-data-table
        v-model="candidateSelection"
        :single-select="false"
        show-select
        :headers="headers"
        :items="filteredCandidates"
        :loading="candidateTableLoading"
        @click:row="handleCandidate"
        :search="searchCandidate"
        :items-per-page="15"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.statusColor">
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.stage="{ item }">
          <!-- <v-select
            dense
            outlined
            :items="testStagesSelector"
            item-text="text"
            item-value="stage"
            v-model="testStages[item.stage]"
            @change="candidateStageUpdate(item)"
            @click.stop
            hide-details
          >
          </v-select> -->
          <v-chip>
            {{ testStagesPretty[testStages[item.stage]] }}
          </v-chip>
        </template>
        <template v-slot:item.rating="{ item }">
          <v-rating :value="item.rating" color="primary" length="5"> </v-rating>
          <!-- <v-rating
            color="primary"
            hover
            length="5"
            v-model="item.rating"
            @click.stop
            @input="candidateRatingUpdate(item)"
          ></v-rating> -->
        </template>
        <template v-slot:item.delaction="{ item }">
          <v-btn icon @click.stop="removeCandidate(item)">
            <v-icon color="danger">
              {{ mdiTrashCan }}
            </v-icon>
          </v-btn>
          <SmallConfirmation ref="delconfirm" />
        </template>
        <template v-slot:item.resumeLink="{ item }">
          <v-btn icon :href="openLink(item)" target="_blnak">
            <v-icon class="text-none text-body-1" color="primary">
              Open Resume
            </v-icon>
          </v-btn>
          <SmallConfirmation ref="delconfirm" />
        </template>
        <template v-if="tableType !== 'JOB'" v-slot:no-data>
          <div class="d-flex justify-center">
            <span class="d-flex flex-column py-5">
              <v-img
                src="~@/assets/images/campaign/best.png"
                width="350"
                max-height="380"
              ></v-img>
              <v-btn
                color="primary"
                x-large
                text
                class="text-none text-h5"
                @click="dialog = true"
              >
                <v-icon large left color="primary">
                  {{ mdiCloudUpload }}
                </v-icon>
                Invite Candidates
              </v-btn>
            </span>
          </div>
        </template>
      </v-data-table>
</div>
</template>
<script>
import { mdiCloudUpload, mdiCloudDownload, mdiTrashCan, mdiMagnify } from "@mdi/js";
import {mapGetters, mapState} from "vuex";
import Invite from "@/components/recruiter/Invite.vue";
import SmallConfirmation from "@/components/assessment/SmallConfirmation.vue";

export default {
  data() {
    return {
      dialog: false,
      infoPanel: 0,
      candidateSelection: [],
      mdiTrashCan,
      mdiCloudUpload,
      mdiCloudDownload,
      mdiMagnify,
      downloadsLoading: false,
      candidatesCsv: null,
      bulkDownload: false,
      candidateCsvText: "",
      candidateHeaders: [
        {
          text: "Candidate",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Test Status",
          value: "status",
        },
        {
          text: "Stage",
          value: "stage",
        },
        {
          text: "Rating",
          value: "rating",
          align: "center",
          sort: (a, b) => b - a,
        },
        {
          text: "Delete",
          value: "delaction",
        },
      ],
      jobHeaders: [
        {
          text: "Candidate",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Resume",
          value: "resumeLink",
        },
      ],
      searchCandidate: "",
      ratingsSelected: [],
      ratingFilters: [
        {
          text: "1",
          value: 1,
        },
        {
          text: "2",
          value: 2,
        },
        {
          text: "3",
          value: 3,
        },
        {
          text: "4",
          value: 4,
        },
        {
          text: "5",
          value: 5,
        },
      ],
      statusSelected: [],
      statusFilters: [
        {
          text: "Invited",
          value: 1,
        },
        {
          text: "In Progress",
          value: 2,
        },
        {
          text: "Completed",
          value: 3,
        },
      ],
      stageSelected: [],
      stageFilters: [
        {
          text: "Screening",
          value: 1,
        },
        {
          text: "Interview",
          value: 2,
        },
        {
          text: "Shortlisted",
          value: 3,
        },
        {
          text: "Selected",
          value: 4,
        },
        {
          text: "Rejected",
          value: 5,
        },
      ],
    };
  },
  props: {
    displayCandidates: {
      type: Array,
      require: true,
    },
    candidateTableLoading: {
      type: Boolean,
      require: true,
    },
    tableType: {
      type: String,
      require: true,
    }
  },
  computed: {
    ...mapGetters("recruiter", [
      "testStages",
      "testStagesSelector",
      "testStagesPretty",
    ]),
    ...mapGetters("customer", ["hiringPlanKeys"]),
    ...mapState("customer", ["customer"]),
		...mapState("jobs", ["allJobCandidates"]),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()];
    },
    hasPremium() {
      return this.hiringPlan === "SCALE";
    },
    headers() {
      console.log("JT ...", this.tableType)
      if(this.tableType === 'JOB') return this.jobHeaders;
      else return this.candidateHeaders;
    },
    filteredCandidates() {
      let filtered = this.displayCandidates;
      // if no filter, display all
      filtered = this.displayCandidates
        .filter((c) => {
          // rating filter
          if (this.ratingsSelected.length >= 1) {
            return this.ratingsSelected.includes(c.rating);
          } else return true;
        })
        .filter((c) => {
          // status filter
          if (this.statusSelected.length >= 1) {
            return this.statusSelected.includes(c.statusInt);
          } else return true;
        })
        .filter((c) => {
          // stage filter
          if (this.stageSelected.length >= 1) {
            return this.stageSelected.includes(c.stage);
          } else return true;
        });
        console.log("Filtered candidates ...", filtered)
      return filtered;
    },
  },
  methods: {
    removeSelection(type, item) {
      if (type === "STATUS") {
        this.statusSelected = [
          ...this.statusSelected.filter((v) => v !== item.value),
        ];
      } else if (type === "RATING") {
        this.ratingsSelected = [
          ...this.ratingsSelected.filter((v) => v !== item.value),
        ];
      } else if (type === "STAGE") {
        this.stageSelected = [
          ...this.stageSelected.filter((v) => v !== item.value),
        ];
      } else {
      }
    },
    candidateRatingUpdate({ id, email, rating }) {
      // @TODO: Pending, event propagation wasnt stopping properly
      console.log(`candidateRatingUpdate`, { id, email, rating });
    },
    async deleteMultipleCandidates() {
      console.log("Emiting delete multiple");
      const toDeleteEmails = this.candidateSelection.map((item) => item.email);
      if (this.candidateSelection.length === 0) {
        return;
      }
      if (
        await this.$refs.delconfirm.open(
          `Are you sure you want to delete ${toDeleteEmails.length} candidates?`
        )
      ) {
      this.$emit("deleteMultipleCandidates", toDeleteEmails);
      }
    },
    handleCandidate(row) {
      console.log("Emiting handle candidate", row);
      this.$emit("handleCandidate", row);
    },
    returnToAllAssessment() {
      console.log("Emiting return to all assessment");
      this.dialog = false;
      this.$emit("returnToAllAssessment");
    },
    async removeCandidate(item) {
      if(this.tableType === 'JOB') {
         await this.$refs.delconfirm.open(
          "Incoming feature"
        )
      }
      else if (
        await this.$refs.delconfirm.open(
          "Are you sure you want to delete this candidate?"
        )
      ) {
        console.log("Emiting remove candidate", item);
        this.$emit("removeCandidate", item);
      }
    },
    openLink(item) {
      console.log("item...", item)
      return item.resumeLink;
    },
    bulkDownloadCandidates() {
			this.clearCandidatesCsv();
      var header = "Name, Email, Date applied\n";
			this.candidateCsvText = header + this.allJobCandidates
			.map(cand => {
				return cand.getResume().getName() + ","  + cand.getResume().getEmail() + "," + new Date(cand?.getAppliedOn()).toDateString();
			}).join("\n");
      console.log("Csv", this.candidateCsvText);
    },

    clearCandidatesCsv() {
      this.candidatesCsv = null;
    },
  },
  components: { Invite, SmallConfirmation},
  watch: {
  },
};
</script>

<style scoped>
tr {
  cursor: pointer;
}
</style>