<template>
  <v-card>
    <v-card-title class="text-h5 justify-space-between">  
      <span>
        Invite Candidates
      </span>
      <v-btn icon @click="closeDialog">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="inviteRef" v-model="inviteForm">
          <v-row align="center" justify="center">
            <v-col>
              <label class="text-h6 font-weight-bold" for="name">
                Candidate Name
              </label>
              <v-text-field
                id="name"
                outlined
                v-model="candName"
                placeholder="Full name"
              />
            </v-col>
            <v-col>
              <label class="text-h6 font-weight-bold" for="email">
                Candidate Email
              </label>
              <v-text-field
                id="email"
                outlined
                v-model="email"
                placeholder="Enter Email"
                :rules="[
                  (v) => !!v || 'Required!',
                  (v) => /.+@.+/.test(v) || 'E-mail must be valid',
                ]"
              />
            </v-col>
              <v-col class="d-flex flex-row-reverse">
                <v-btn
                  x-large
                  color="success"
                  @click="validateInvite"
                  :disabled="!inviteForm"
                  class="px-5 py-3 text-none text-body-1"
                  :loading="submitted"
                >
                  Send Invite
                </v-btn>
              </v-col>
          </v-row>
      </v-form>
    </v-card-text>
    <v-row>
      <v-col class="text-center text-h6"> - OR - </v-col>
    </v-row>
    <v-card-actions>
    <v-row justify="center" align="center">
      <div class="text-center pb-5">
        <v-btn
          @click="showBulkInvite"
          class="pa-5 text-h4 primary--text text-none"
          elevation="0"
          color="white"
        >
          <v-icon large left> {{ mdiStarCircle }} </v-icon>
          Invite Candidates in Bulk
        </v-btn>
      </div>
    </v-row>
    <v-row v-if="bulkInvite">
      <v-col cols="12" md="8">
        <v-file-input
          accept=".csv"
          label="Attach CSV File"
          hint="Columns format: name,email"
          persistent-hint
          outlined
          prepend-icon=""
          :prepend-inner-icon="mdiAttachment"
          v-model="candidatesCsv"
        ></v-file-input>
      </v-col>
      <v-col class="d-flex flex-row-reverse">
        <v-btn
          x-large
          color="success"
          class="px-5 py-6 text-none text-body-1"
          :loading="invitesLoading"
          height="35"
          @click="bulkInviteCandidates"
          :disabled="!candidatesCsv"
        >
          Send Invites
        </v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-btn
        v-if="csvErrorText.length > 0"
        x-large
        color="success"
        class="px-5 py-6 text-none text-body-1 font-weight-medium"
        download="candidates_error.csv"
        :href="`data:application/octet-stream,${encodeURI(this.csvErrorText)}`"
      >
        Download CSV with errors
      </v-btn>
    </v-row>
    <go-premium ref="unlock" imageUrl="images/GoPremium.png"> </go-premium>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mdiStarCircle, mdiAttachment, mdiClose} from "@mdi/js";
import GoPremium from "../assessment/GoPremium.vue";

export default {
  components: { GoPremium },
  data() {
    return {
      mdiStarCircle,
      mdiAttachment,
      mdiClose,
      candName: "",
      email: "",
      submitted: false,
      inviteForm: null,
      inviteDailog: false,
      candidatesCsv: null,
      invitesLoading: false,
      bulkInvite: false,
      csvErrorText: "",
    };
  },
  computed: {
    ...mapState("recruiter", ["assessment"]),
    ...mapGetters("customer", ["hiringPlanKeys"]),
    ...mapState("customer", ["customer"]),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()];
    },
    hasPremium() {
      return this.hiringPlan === "SCALE";
    },
  },
  methods: {
    ...mapActions("recruiter", ["assessmentInvite", "assessmentInviteBatch"]),
    validateInvite() {
      this.$refs.inviteRef.validate() && this.invite();
    },
    invite() {
      console.log("Sending invite ...")
      this.submitted = true;
      this.assessmentInvite({
        id: this.assessment?.getId(),
        name: this.candName,
        email: this.email,
      })
        .then((res) => {
          this.candName = "";
          this.email = "";
          this.submitted = false;
          this.$store.dispatch("notifs/addNotif", {
            text: "Candidate Invited Successfully!",
            type: "success",
          });
          this.$router.replace({ query: null });
          this.$emit("close");
        })
        .catch((e) => {
          this.submitted = false;
          this.$store.dispatch("notifs/addNotif", {
            text: e?.message || "Error Inviting Candidate, please retry!",
            type: "error",
          });
          // console.error(e);
        });
    },
    async showBulkInvite() {
      if (!this.hasPremium) {
        await this.$refs.unlock.open(
          "To UNLOCK this feature, become a PREMIUM member today!"
        );
      } else {
        this.bulkInvite = true;
      }
    },
    async bulkInviteCandidates() {
      console.log("Saving csv ..");
      this.invitesLoading = true;
      await this.candidatesCsv.text().then((content) => {
        this.assessmentInviteBatch({
          candidatesCsv: content,
          id: this.assessment?.getId(),
        })
          .then((res) => {
            this.clearCandidatesCsv();
            this.invitesLoading = false;
            if (res?.toObject().error) {
              // show csv somehow, since there are errors
              this.csvErrorText = res?.getResults();
              this.$store.dispatch("notifs/addNotif", {
                text: "Some errors found in CSV",
                type: "error",
              });
            } else {
              this.$store.dispatch("notifs/addNotif", {
                text: "Candidates Invited Successfully!",
                type: "success",
              });
              this.$router.replace({ query: null });
              this.$nextTick(() => {
                this.$emit("close");
              });
            }
          })
          .catch((e) => {
            this.invitesLoading = false;
            this.$store.dispatch("notifs/addNotif", {
              text:
                e?.message || "Error Bulk Inviting Candidates, please retry!",
              type: "error",
            });
            // console.error(e);
          });
      });
    },

    clearCandidatesCsv() {
      this.candidatesCsv = null;
    },

    closeDialog() {
      this.$emit("closeDialog")
    }
  },
};
</script>
